import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import {
  Box,
  Typography,
  GlobalStyles,
  CircularProgress,
  Button,
  Divider,
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HomeIcon from '@mui/icons-material/Home';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';

import ROUTES from '@giro-pdv/constants/routes.constant';

import LogoComponent from '@giro/shared-components/Logo.component';

import receipt from '../store/receipt';

const PrintLayout = ({ children, orientation = 'portrait' }) => {
  return (
    <Box
      width={orientation === 'portrait' ? '210mm' : '297mm'}
      p={4}
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden"
      bgcolor="white"
      borderRadius={1}
    >
      {children}
    </Box>
  );
};

const BoxA4 = ({ children, loading = false }) => {
  return (
    <PrintLayout>
      {children}

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={9999}
          bgcolor="white"
        >
          <CircularProgress />
        </Box>
      )}
    </PrintLayout>
  );
};

const Typo = ({ children, ...restProps }) => (
  <Typography variant="body_sm" color="gray.500" {...restProps}>
    {children}
  </Typography>
);

const ItemReceipt = ({ label, value, color }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    bgcolor={color ? 'gray.200' : undefined}
    p={2}
    borderRadius={1}
    gap={10}
    alignItems="center"
    sx={{
      '@media print': {
        WebkitPrintColorAdjust: 'exact',
      },
    }}
  >
    <Typography color="black" fontWeight={600} variant="body_sm">
      {label}
    </Typography>
    <Box display="flex" flexDirection="column">
      {value}
    </Box>
  </Box>
);

const Content = () => {
  const selectorRedux = {
    data: useSelector(receipt.selector.selectData),
  };

  const dateItems = ['dtTransacao'];
  const currencyItems = ['vlTransacao'];

  const normalizeText = {
    bandeira: 'Bandeira',
    tipoPagamento: 'Tipo de pagamento',
    parcelas: 'Parcelas',
    dtTransacao: 'Data de transação',
    vlTransacao: 'Valor da transação',
    recibo: 'Recibo',
    binCartao: 'Cartão',
    softDescriptor: 'Descrição',
    cidade: 'Cidade',
  };

  const discoverValue = (el, value) => {
    const isDate = dateItems.includes(el);

    if (isDate) {
      return format(new Date(value), 'dd/MM/yyyy HH:mm');
    }

    const isCurrency = currencyItems.includes(el);

    if (isCurrency) {
      return Number(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    return value;
  };

  const itemsFormatted = Object.keys(selectorRedux.data)?.map(
    (el) =>
      selectorRedux.data[el] &&
      normalizeText[el] && {
        label: normalizeText[el],
        value: <Typo>{discoverValue(el, selectorRedux.data[el])}</Typo>,
      }
  );

  const items = itemsFormatted;

  const debtsValue = (
    <Typo align="right">
      BOMBEIROS 2022 R$ 53,96, INFRACAO AMTTRANS 2021 R$ 133,35, INFRACAO
      DER/LOMBADA 2021 R$ 132,41, INFRACAO RENAINF 2020 R$ 203,64, INFRACAO
      RENAINF 2020 R$ 136,34, INFRACAO RENAINF 2020 R$ 135,77, INFRACAO RENAINF
      2021 R$ 202,68, INFRACAO RENAINF 2021 R$ 135,77, INFRACAO RENAINF 2021 R$
      135,13, INFRACAO RENAINF 2021 R$ 135,13, INFRACAO RENAINF 2021 R$ 132,41,
      INFRACAO SSP-PCR - FOTOSENSORES 2021 R$ 104.13, INFRACAO SSP-PCR 2020 R$
      104,13, INFRACAO SSP-PCR 2021 R$ 104,13, IPVA 2021 - Parcela UNICA R$
      2.824,08, IPVA 2022 - Parcela UNICA R$2.601,07
    </Typo>
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={1} alignSelf="center">
        <LogoComponent
          variant="new"
          style={{ width: 250, alignSelf: 'center' }}
        />
      </Box>
      <Typography align="center">
        Código de autorização: {selectorRedux?.data?.cdTransacao}
      </Typography>
      <Box>
        {items.filter(Boolean).map((id: any, idx) => (
          <ItemReceipt
            color={idx % 2 === 0}
            key={id.label}
            label={id.label}
            value={id.value}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="center" gap={1}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <EmailIcon sx={{ fontSize: 16 }} />
              <Typography variant="body_sm" align="center">
                atendimento@giropagamentos.com.br
              </Typography>
            </Box>
            /
            <Box display="flex" alignItems="center" gap={0.5}>
              <LanguageIcon sx={{ fontSize: 16 }} />
              <Typography variant="body_sm" align="center">
                www.giropagamentos.com.br
              </Typography>
            </Box>
          </Box>

          <Typography variant="body_sm" align="center">
            Copyright © 2023 Giro Pagamentos. Todos os direitos reservados
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ReceiptScreen = () => {
  const history = useHistory();

  return (
    <>
      <GlobalStyles
        styles={{
          '.sidebar-container, .header-container': {
            display: 'none !important',
          },
          '#containerScroll': {
            height: '100% !important',
          },
          body: {
            backgroundColor: '#F2F5F6 !important',
          },
          '@page': {
            margin: '0px',
            // size: 'landscape',
          },
          '@media print': {
            'html, body': {
              height: '100%',
            },
            '.md-chat-widget-btn-container': {
              display: 'none !important',
            },
          },
        }}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <BoxA4>
          <Box
            mb={5}
            display="flex"
            flexDirection="column"
            sx={{ '@media print': { display: 'none' } }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              mb={4}
              alignItems="center"
            >
              <Button
                onClick={() => history.push(ROUTES.HOME)}
                variant="outlined"
                color="primary"
              >
                <ArrowBackIosNewIcon
                  sx={{
                    fontSize: 15,
                    mr: 0.5,
                    color: 'primary.400',
                  }}
                />
                Voltar
              </Button>

              <Button variant="contained" onClick={() => window.print()}>
                <PrintIcon sx={{ fontSize: 15, mr: 0.5 }} />
                Imprimir
              </Button>
            </Box>
            <Divider />
          </Box>

          <Content />
        </BoxA4>
      </Box>
    </>
  );
};

export default ReceiptScreen;
